import pluralize from 'pluralize';
import { useMemo } from 'react';

import { type EnumsPageName } from '@lp-lib/api-service-client/public';

import { useArrayState } from '../../hooks/useArrayState';
import { useLiveAsyncCall } from '../../hooks/useAsyncCall';
import { safeWindowReload } from '../../logger/logger';
import { apiService } from '../../services/api-service';
import { type Tag, TagUtils } from '../../types';
import { err2s } from '../../utils/common';
import { DragDropList } from '../common/DragDrop';
import { useAwaitFullScreenConfirmCancelModal } from '../ConfirmCancelModalContext';
import { ModalWrapper } from '../ConfirmCancelModalContext/ModalWrapper';
import { DeleteIcon } from '../icons/DeleteIcon';
import { MenuIcon } from '../icons/MenuIcon';
import { Loading } from '../Loading';
import { useFeaturedTags } from './hooks';
import { TagPicker } from './TagPicker';

interface EditCategoriesModalProps {
  context: EnumsPageName;
  onComplete: () => void;
  onClose: () => void;
}

function EditCategoriesModalInternal(
  props: EditCategoriesModalProps & {
    initTags: Tag[];
  }
) {
  const [tags, , dao] = useArrayState<Tag>({
    prepend: true,
    compare: (a, b) => a.id === b.id,
    init: props.initTags,
  });

  const {
    call: handleSave,
    state: { state, error },
  } = useLiveAsyncCall(async () => {
    await apiService.tag.updateFeaturedTags(
      props.context,
      tags.map((t) => t.id)
    );
    safeWindowReload();
    props.onComplete();
  });

  const objectType = useMemo(
    () => TagUtils.getObjectType(props.context),
    [props.context]
  );
  const objectLabel = useMemo(
    () => TagUtils.getObjectLabel(objectType),
    [objectType]
  );

  const handleFilter = (tag: Tag): boolean => {
    return (
      TagUtils.getObjectsCount(tag, objectType) > 0 &&
      !tags.find((t) => t.id === tag.id)
    );
  };

  const formatTag = (tag: Tag): string => {
    const count = TagUtils.getObjectsCount(tag, objectType);
    return pluralize(objectLabel, count, true);
  };

  return (
    <ModalWrapper
      onClose={props.onClose}
      borderStyle='white'
      containerClassName='w-160'
    >
      <div className='w-full px-6 py-8 text-white flex flex-col items-center'>
        <div className='w-full'>
          <h2 className='text-2xl text-center mb-6'>Edit Categories</h2>
          <div className='text-base font-medium my-2'>
            Add a Featured Category
          </div>
          <TagPicker
            placeholder='Search for an existing Category'
            filter={handleFilter}
            onChange={dao.addItem}
            formatMeta={formatTag}
            multi={false}
          />
          <div className='text-base font-medium my-2'>Featured Categories</div>

          <DragDropList
            type={`categories-${props.context}`}
            items={tags}
            onMove={dao.moveItem}
            render={({ item: tag, drag, ref, style }) => (
              <div
                className={`w-full flex items-center`}
                ref={ref}
                style={style}
              >
                <button type='button' ref={drag} className='btn cursor-move'>
                  <MenuIcon />
                </button>
                <div className='flex items-center justify-between flex-grow mx-2 my-1 px-3 border border-secondary rounded-xl h-10 select-none'>
                  <div className='text-sms'>{tag.name}</div>
                  <div className='text-sms text-secondary'>
                    {formatTag(tag)}
                  </div>
                </div>
                <button
                  type='button'
                  className='btn flex w-10 h-10 border border-secondary rounded-xl items-center justify-center text-red-002'
                  onClick={() => dao.deleteItem(tag)}
                >
                  <DeleteIcon />
                </button>
              </div>
            )}
          />

          {tags.length === 0 && (
            <div className='text-secondary test-sms text-center'>
              No categories
            </div>
          )}

          {error && <div className='text-red-002 text-sms'>{err2s(error)}</div>}

          <div className='mt-7 flex justify-center'>
            <button
              type='button'
              className='btn-secondary w-32 h-10'
              onClick={props.onClose}
            >
              Cancel
            </button>
            <button
              type='button'
              className='btn-primary w-32 h-10 ml-2'
              disabled={state.isRunning}
              onClick={handleSave}
            >
              {state.isRunning ? 'Saving...' : 'Save'}
            </button>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
}

function EditCategoriesModal(props: {
  context: EnumsPageName;
  onComplete: () => void;
  onClose: () => void;
}) {
  const { data, isLoading } = useFeaturedTags(props.context);

  if (isLoading || !data) return <Loading />;
  return <EditCategoriesModalInternal {...props} initTags={data} />;
}

export function EditCategoriesButton(props: {
  context: EnumsPageName;
  className?: string;
}) {
  const triggerFullScreenModal = useAwaitFullScreenConfirmCancelModal();

  const handleClick = () => {
    triggerFullScreenModal({
      kind: 'custom',
      element: (p) => (
        <EditCategoriesModal
          context={props.context}
          onComplete={p.internalOnConfirm}
          onClose={p.internalOnCancel}
        />
      ),
    });
  };

  return (
    <button
      type='button'
      className={`btn-secondary text-white w-44 h-10 ${props.className}`}
      onClick={handleClick}
    >
      Edit Categories
    </button>
  );
}
